<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    title="New services"
    button2-text="Save"
    :main-btn-disabled="services.length < 1"
    @button2="save"
    :max-width="700"
  >
    <template v-slot:content>
      <v-card flat min-height="400">
        <v-card-text>
          <v-row align="center" dense justify="space-between">
            <v-col cols="10">
              <label class="primary--text subtitle-2">New Service Name</label>
              <v-text-field
                v-model.trim="name"
                @keyup.enter="addService"
                outlined
                placeholder="Enter service name"
                hide-details="auto"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="d-flex">
              <v-btn
                color="primary"
                height="40"
                class="align-self-center mt-5 mx-auto rounded-10"
                :depressed="!name"
                :disabled="!name"
                @click="addService"
              >
                <v-icon color="white"> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-sheet v-if="services.length" class="fullwidth">
            <h3 class="text-center mb-3 subtitle-2 mt-2 primary--text">
              Services
            </h3>
            <v-row
              align="center"
              dense
              justify="space-between"
              v-for="(service, index) of services"
              :key="index"
            >
              <v-col cols="10">
                <label class="primary--text subtitle-2">
                  Service Name {{ index + 1 }}
                </label>
                <v-text-field
                  v-model="service.name"
                  outlined
                  dense
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="2" class="text-xs-right d-flex">
                <v-btn
                  elevation="1"
                  class="align-self-center mx-auto rounded-10 mt-5"
                  color="secondary"
                  height="40"
                  @click="deleteService(index)"
                >
                  <v-icon color="white">mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-sheet>
          <Empty v-else headline="Create services"></Empty>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
export default {
  name: 'ServiceMultipleAddDialog',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false }
  },
  computed: {},
  data: () => ({
    name: null,
    services: [],
    dialog: false
  }),
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
      if (val) {
        this.services = []
      }
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    addService() {
      if (!this.name) {
        this.appSnackbar('Service name required!', 'error')
        return
      }
      this.services.unshift({ name: this.name })
      this.name = null
    },
    deleteService(index) {
      this.services.splice(index, 1)
    },
    save() {
      if (this.services.length < 1) {
        this.appSnackbar('No service created', 'error')
        return
      }
      this.$emit('save', this.services)
    }
  }
}
</script>
<style lang="scss" scoped></style>
