import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
//Components
import VueTable from '@/common/VueTable/VueTable.vue'
import Breadcrumb from '@/common/Breadcrumb.vue'
import ServiceMultipleAddDialog from './components/ServiceMultipleAddDialog.vue'
import ServicesEditDialog from './components/ServicesEditDialog.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import TableHeader from '@/common/TableHeader.vue'
import Actions from '@/common/VueTable/Actions.vue'
import ActionDropdown from '@/common/VueTable/ActionDropdown.vue'
import ImageCropper from '@/common/Dialogs/ImageCropper.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'Services',
    mixins: [fetch_services],
    components: {
        VueTable,
        Breadcrumb,
        ServiceMultipleAddDialog,
        ServicesEditDialog,
        DeleteDialog,
        TableHeader,
        Actions,
        ActionDropdown,
        ImageCropper
    },
    computed: {
        ...mapGetters(['view_type'])
    },
    data() {
        return {
            icon_uploader: false,
            paths: [
                { text: 'Dashboard', disabled: false, router_name: 'default-content' },
                { text: 'Services', disabled: true, router_name: null }
            ],
            headers: [
                { text: 'Icon', sortable: false, align: 'center', width: 40 },
                { text: 'Service Name', value: 'name', sortable: true },
                { text: 'Created By', value: 'campaigns_count', sortable: true },
                {
                    text: 'Projects Created',
                    value: 'created_at',
                    sortable: true,
                    align: 'center'
                },
                {
                    text: 'Date Created',
                    value: 'created_at',
                    sortable: true
                },
                {
                    text: 'Actions',
                    value: 'action',
                    sortable: false,
                    align: 'center',
                    width: '140px'
                }
            ],
            activeService: null
        }
    },

    mounted() {
        this.$event.$emit('path-change', this.paths)
        this.loadItems(`api/services-list`, true, null, null)
    },

    methods: {
        toggleAll() {
            if (this.selected.length) this.selected = []
            else this.selected = this.items.slice()
        },
        handleUpdate(payload) {
            this.updateItem(
                `api/services-list/${this.activeItem.id}`,
                payload,
                'Service updated',
                () => {
                    this.action_edit_dialog(false, null)
                },
                null,
                null
            )
        },
        handleSave(payload) {
            this.addItems(
                'api/services-list', { names: payload.map((i) => i.name) },
                'New service(s) added.',
                () => {
                    this.action_add_dialog(false, null)
                },
                null,
                null
            )
        },
        openIconModal(item) {
            this.activeService = item
            this.icon_uploader = true
        },
        setIcon(payload) {
            request
                .put(`api/services-list/${this.activeService.id}/set-icon`, payload)
                .then(({ data }) => {
                    let index = this.items.findIndex(
                        (i) => i.id === this.activeService.id
                    )
                    if (~index) {
                        this.items.splice(index, 1, data)
                    }
                    this.appSnackbar('Icon updated')
                    this.icon_uploader = false
                })
                .finally(() => {
                    this.activeService = null
                })
        }
    }
}