<template>
  <CustomDialog
    title="Edit Service"
    :open.sync="open"
    button2-text="Save"
    :main-btn-disabled="!valid"
    @button1="open = false"
    @button2="save"
  >
    <template slot="content">
      <v-form v-model="valid">
        <div class="mb-1">
          <label class="primary--text subtitle-2">Service Name </label>
          <!-- <v-combobox class="service__input" label="Service name" v-model.trim="name" @keyup.enter="add_service" solo hide-details color="#657186" :items="recommendedServices"></v-combobox> -->
          <v-text-field
            outlined
            dense
            v-model="name"
            hide-details="auto"
            :rules="requiredRules"
          ></v-text-field>
        </div>
        <div class="mb-1">
          <label class="primary--text subtitle-2">Service Description </label>
          <v-textarea
            outlined
            dense
            auto-grow
            rows="3"
            v-model="description"
            hide-details="auto"
          ></v-textarea>
        </div>
        <v-row dense align="center" justify="space-between">
          <v-col :cols="mdAndUp ? 4 : 12">
            <label class="primary--text subtitle-2">Icon</label>
            <div class="fullwidth d-flex align-center">
              <v-avatar
                class="mx-auto cursor-pointer"
                @click="icon_uploader = true"
              >
                <v-img
                  :lazy-src="settings.loader"
                  contain
                  size="50"
                  v-if="icon"
                  :src="icon.url_inline"
                >
                  <template v-slot:placeholder>
                    <v-icon x-large>mdi-image</v-icon>
                  </template>
                </v-img>
                <v-icon color="primary" v-else size="50">mdi-image</v-icon>
              </v-avatar>
            </div>
          </v-col>
          <v-col :cols="mdAndUp ? 8 : 12">
            <label class="primary--text subtitle-2">Status</label>
            <v-select
              hide-details="auto"
              :items="[
                { text: 'Active', value: 'active' },
                { text: 'Inactive', value: 'inactive' }
              ]"
              v-model="status"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
      </v-form> 
      <ImageCropper
        @file="setIcon"
        title="Upload Icon"
        width="100"
        height="100"
        v-model="icon_uploader"
        value-format="attachment"
      >
      </ImageCropper>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue' 
import ImageCropper from '@/common/Dialogs/ImageCropper.vue'
export default {
  name: 'ServicesEditDialog',
  props: {
    value: Boolean,
    item: { type: Object }
  },
  components: {
    CustomDialog,
    ImageCropper
  }, 
  data: () => ({
    open: false,
    name: null,
    description: null,
    icon: null,
    status: 'active',
    valid: false,
    icon_uploader: false,
    recommendedServices: []
  }),
  watch: {
    value(new_val) {
      this.open = new_val
    },
    open(new_val) {
      this.$emit('input', new_val)
    },
    item: {
      handler: function (new_val) {
        this.update_fields(new_val)
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    cancel() {
      this.open = false
      this.$emit('cancelled', true)
    },
    setIcon(attachment) {
      this.icon = attachment
    },
    save() {
      const fields_to_save = {
        name: this.name,
        status: this.status,
        description: this.description,
        attachment: this.icon
      }
      this.$emit('save', fields_to_save)
    },
    update_fields(fields) {
      this.name = fields ? fields.name : null
      this.status = fields ? fields.status : 'inactive'
      this.description = fields ? fields.description : null
      this.icon = fields ? fields.attachment : null
    } 
  }
}
</script>
<style lang="scss" scoped></style>
